import {
  Alert,
  AlertTitle,
  Button,
  CssBaseline,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import "./App.css";
import logo from "./assets/img/logo.png";
import illustration from "./assets/img/illus.svg";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Footer from "./components/Footer";
import AppBar from "./components/AppBar";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useState, useEffect } from "react";
import moment from "moment";
import * as Realm from "realm-web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#41BC3F",
      secondary: "rgb(45,55,72)",
    },
    secondary: {
      main: "rgb(45,55,72)",
    },
    neutral: {
      main: "#455a64",
      contrastText: "#fff",
    },
  },
});

const Timer = () => {
  const launchDate = moment(new Date("20 June 2022"));

  const [timeDifference, setTimeDifference] = useState(
    launchDate.diff(moment())
  );

  const remainingTime = {
    days: launchDate.diff(moment(), "days"),
    months: moment.duration(timeDifference).hours(),
    minutes: moment.duration(timeDifference).minutes(),
    seconds: moment.duration(timeDifference).seconds(),
  };

  useEffect(() => {
    const updateDate = () => {
      setTimeDifference(launchDate.diff(moment()));
    };
    setInterval(() => {
      updateDate();
    }, 1000);
  }, [launchDate]);

  return (
    <>
      <Stack
        sx={{ mt: 2 }}
        direction="row"
        spacing={2}
        justifyContent="space-evenly"
      >
        <Box>
          <Typography
            align="center"
            color="primary"
            variant="h5"
            sx={{ fontWeight: "bold" }}
          >
            {remainingTime.days}
          </Typography>
          <Typography color="text.secondary" align="center">
            Days
          </Typography>
        </Box>
        <Box>
          <Typography
            align="center"
            color="primary"
            variant="h5"
            sx={{ fontWeight: "bold" }}
          >
            {remainingTime.months}
          </Typography>
          <Typography color="text.secondary" align="center">
            Hours
          </Typography>
        </Box>
        <Box>
          <Typography
            align="center"
            color="primary"
            variant="h5"
            sx={{ fontWeight: "bold" }}
          >
            {remainingTime.minutes}
          </Typography>
          <Typography color="text.secondary" align="center">
            Minutes
          </Typography>
        </Box>
        <Box>
          <Typography
            align="center"
            color="primary"
            variant="h5"
            sx={{ fontWeight: "bold" }}
          >
            {remainingTime.seconds}
          </Typography>
          <Typography color="text.secondary" align="center">
            Seconds
          </Typography>
        </Box>
      </Stack>
    </>
  );
};

const Socials = () => {
  return (
    <>
      <Stack direction="row" spacing={2} justifyContent="center" sx={{ mt: 2 }}>
        <FacebookIcon color="neutral" sx={{ cursor: "pointer" }} />
        <TwitterIcon color="neutral" sx={{ cursor: "pointer" }} />
        <InstagramIcon color="neutral" sx={{ cursor: "pointer" }} />
      </Stack>
    </>
  );
};

const Subscription = ({ user }) => {
  const [userMail, setUserMail] = useState("");
  const [success, setSuccess] = useState(false);

  const handleSubmit = async () => {
    if (userMail) {
      try {
        await user.functions.subscribe(userMail);
        // console.log(res);
      } catch (err) {
        console.log(err.message);
      }
      setUserMail("");
      setSuccess(true);
    }
  };

  if (success)
    return (
      <>
        <Box sx={{ mt: 2 }}>
          <Alert severity="success">
            <AlertTitle>Email Sent</AlertTitle>
            Thank you for subscribing!
            {/* — <strong>Add phone number!</strong> */}
          </Alert>
        </Box>
      </>
    );
  else
    return (
      <Stack direction={{ xs: "column", md: "row" }} spacing={2} mt={2}>
        <TextField
          label="Enter your email"
          value={userMail}
          onChange={(e) => setUserMail(e.target.value)}
          color="secondary"
          size="small"
          fullWidth
          sx={{
            "& input:valid + fieldset": {
              borderColor: "rgb(45,55,72)",
              borderWidth: 2,
            },
          }}
        />
        <Button
          onClick={handleSubmit}
          size="small"
          variant="contained"
          color="secondary"
          sx={{ px: 3 }}
        >
          Notify&nbsp;Me
        </Button>
      </Stack>
    );
};

const App = () => {
  // Add your App ID
  const app = new Realm.App({ id: "webapp-tjhjm" });
  // Create an anonymous credential
  const credentials = Realm.Credentials.anonymous();

  const [user, setUser] = useState("");

  useEffect(() => {
    const login = async () => {
      try {
        const updatedUser = await app.logIn(credentials);
        setUser(updatedUser);
      } catch (err) {
        console.log(err.message);
      }
    };

    login();
  }, [app, credentials]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
          }}
        >
          <CssBaseline />

          <AppBar />
          <Container
            sx={{
              display: "flex",
              minHeight: "70vh",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Stack
              sx={{ my: 3 }}
              spacing={5}
              direction={{ xs: "column-reverse", md: "row" }}
              // direction={{ xs: "column", md: "row" }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: { xs: "none", md: "flex" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    maxWidth: 500,
                  }}
                >
                  <img
                    loading="lazy"
                    alt="abc"
                    width="100%"
                    src={illustration}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box sx={{ maxWidth: 500 }}>
                  <Stack sx={{ alignItems: "center" }}>
                    <img alt="logo" width={150} src={logo} />
                  </Stack>

                  <Typography
                    variant="h4"
                    align="center"
                    sx={{ fontWeight: "500", color: "rgb(45, 55, 72)" }}
                    gutterBottom
                  >
                    We are coming soon
                  </Typography>
                  <Typography
                    align="center"
                    sx={{ maxWidth: 400 }}
                    color="text.secondary"
                  >
                    Our website is under construction. We'll be here soon with
                    our new awesome site, subscribe to be notified.
                  </Typography>

                  <Timer />
                  <Subscription user={user} />
                  <Socials />
                </Box>
              </Box>
            </Stack>
          </Container>
          <Footer />
        </Box>
      </ThemeProvider>
    </>
  );
};

export default App;

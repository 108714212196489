import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Avatar, Container } from "@mui/material";
import logoSmall from "../assets/img/logoSmall3.png";

export default function DenseAppBar() {
  return (
    <Box>
      <AppBar position="static" color="">
        <Container>
          <Toolbar variant="dense" sx={{}}>
            <Avatar sx={{ mr: 1, cursor: "pointer" }} src={logoSmall} />

            <Typography
              variant="h6"
              noWrap
              sx={{
                mr: 2,
                // display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                cursor: "pointer",
                fontWeight: 1000,
                letterSpacing: ".1rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              Mistri
            </Typography>
            {/* <Typography variant="h6" color="inherit" component="div">
              Mistri Online
            </Typography> */}
            <Box sx={{ flexGrow: 1 }}></Box>

            <IconButton aria-label="menu" sx={{}}>
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
}
